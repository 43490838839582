import React from 'react';

import { Crossmark } from '@yourmileag/ui-kit';

export function Header(props) {
    function onChange(view) {
        props.setOrder(null);
        props.onChange(view);
    }
    return (
        <div className={'widget__header'}>
            <img className={'widget__header__brand' + (props.minimal ? ' widget__header__brand--minimal' : '')} src={props.config.ui.logo} />
            &nbsp;
            {props.config.app ? (
                <>
                    {!props.minimal && (
                        <ul className={'widget__header__navigation'}>
                            <li><a className={props.currentView == 'order' ? 'active' : ''} onClick={() => onChange('order')}>New ride</a></li>
                            <li><a className={props.currentView == 'order_list' ? 'active' : ''} onClick={() => onChange('order_list')}>All rides</a></li>
                        </ul>
                    )}
                </>
            ) : (
                <a href="." onClick={props.closeWidget} className={'widget__header__close'}>
                    <Crossmark />
                </a>
            )}
        </div>
    );
}
