import React from 'react';
import ReactDOM from 'react-dom';

//import './styles/reset.css';
import './styles/fonts.css';
import './styles/index.css';
import './styles/widget.css';
import './styles/app.css';
import './styles/form.css';
import './styles/card.css';
import './styles/typo.css';
import './styles/waypoints.css';
import './styles/slide.css';
import './styles/stats.css';
import './styles/login.css';
import './styles/order.css';

import Widget from './Widget';

const defaultKey = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJvd25lciI6InNlcnZlciIsImlkZW50aXR5SWQiOiJ3aWRnZXQiLCJzY29wZXMiOnsiZXNzZW50aWFscyI6WyJvcmRlcnM6cmVhZCJdfSwianRpIjoiMDAwMDAwMDAyIn0.PPPPhUNXoaYrT0xf7gaZsTajiAKrSpgjk9-nAqpTiz65nTLYCHOB72kkriZGIpt44O7OSzwVcOfAMvxr2eLWuMVsbzsCgpb1MD5Kc278pSXM5gJpAiVJ_Pk0ZvGqJ-mJKiavlC-rukI5WuhVLGsoSFvh2dCjMy2_dTPLavfSQ5CgwDiuk7mRSTulZC0izEJd0wSiwqp4csqaR4_sg2hap67EkBKGqqdhuegUxBA4kblT9v6Op2AnKGEvPNy3AzVBbRUjNH0DMxQHk3wvc7O1dbfJmw_4WXB8r0ThMYbKLfo-DunjUzGs1dptmA9FSKGy0_je1_DmasvIdJowny4qdw';

let widgetEl = document.getElementById('go_widget')

let key = widgetEl.getAttribute('data-key')
let app = widgetEl.getAttribute('data-app') == 'true'
let fms = widgetEl.getAttribute('data-fms') == 'true'
let logo = widgetEl.getAttribute('data-logo')
let phone = widgetEl.getAttribute('data-phone')
let colorLight = widgetEl.getAttribute('data-color-light')
let colorDark = widgetEl.getAttribute('data-color-dark')

const config = {
  fms: fms,
  app: app,
  api: {
    url: 'https://api-beta.go-fleet.ch',
    key: key ? key : defaultKey,
  },
  phone: phone,
  ui: {
    logo: logo,
    colorLight: colorLight,
    colorDark: colorDark,
  }
}

ReactDOM.render(<Widget config={config} />, widgetEl);
