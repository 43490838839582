import moment from 'moment-timezone';

export const stateLabels = {
    new: 'New',
    assigning: 'Assigning',
    assigned: 'Assigned',
    arriving: 'Arriving',
    arrived: 'Arrived',
    completing: 'Completing',
    completed: 'Completed',
    canceled: 'Canceled',
    deleted: 'Deleted',
    problem: 'Problem', // TODO: Set correct status
};

export function isBlocked() {
    var format = 'hh:mm:ss'
    var timezone = 'Europe/Zurich';

    var now = moment.tz(timezone);
    var startBlockTime = moment.tz('22:30:00', format, timezone);
    var endBlockTime = moment.tz('06:00:00', format, timezone);

    if (now.isBetween(endBlockTime, startBlockTime)) {
        return false;
    } else {
        return true;
    }
}