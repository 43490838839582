import React from 'react';

import { Order } from "./components/Order";
import { Status } from "./components/Status";
import { Header } from './components/Header';
import { Login } from './components/Login';
import { OrderList } from './components/OrderList';
import { Block } from './components/Block';
import { isBlocked } from './helpers';


function Widget(props) {
  // const order = window.location.search.indexOf('?go-id=') >= 0
  const [order, setOrder] = React.useState(null);
  const [blocked, setBlocked] = React.useState(isBlocked());

  const [authenticated, setAuthenticated] = React.useState(false);
  const [currentView, setCurrentView] = React.useState('order');

  const [config, setConfig] = React.useState(props.config);
  const [open, setOpen] = React.useState(order);

  React.useEffect(() => {
    if (window.location.search.indexOf('?go-id=') >= 0) {
      setOrder(window.location.search.split('?go-id=')[1].split('&')[0]);
    }

    if (config.app) {
      openWidget();
    }

    window.setInterval(function () {
      setBlocked(isBlocked());
    }, 1000);

    var triggers = document.getElementsByClassName('open_go_widget');

    for (var i = 0; i < triggers.length; i++) {
      triggers[i].addEventListener('click', openWidget, false);
    }
    return () => {
      for (var i = 0; i < triggers.length; i++) {
        triggers[i].removeEventListener('click', openWidget);
      }
    }
  }, []);

  function openWidget(event) {
    setOpen(true);
  }

  function closeWidget(event) {
    event.preventDefault();
    setOpen(false);
  }

  function onLoginSuccess(data) {
    setConfig({
      ...config,
      partnerName: data.partner_name,
      fleetIds: data.fleet_ids,
      api: {
        ...config.api,
        key: data.api_key,
      }
    })
    setAuthenticated(true);
  }

  return (
    <div className={'widget' + (open ? ' widget--open' : '') + (config.fms ? ' widget--fms' : '')}>
      <style
        dangerouslySetInnerHTML={{ __html: '.custom_button {background-image: linear-gradient(to right, ' + config.ui.colorLight + ', ' + config.ui.colorDark + ') !important;} .custom_button[disabled] {opacity: 0.4;}' }} />
      {!config.app && (
        <a href="." onClick={closeWidget} className={'widget__close'}></a>
      )}
      <div className={'widget__frame'}>
        <div className={'widget__main'}>
          <div className={'widget__content'}>
            {!authenticated ? (
              <>
                {order ? (
                  <>
                    <Header config={config} minimal />
                    <Status config={config} order={order} authenticated={authenticated} />
                  </>
                ) : (
                  <Login config={config} onSuccess={onLoginSuccess} />
                )}
              </>
            ) : (
              <>
                <Header config={config} closeWidget={closeWidget} onChange={(view) => setCurrentView(view)} currentView={currentView} setOrder={setOrder} />
                {order ? (
                  <Status config={config} order={order} authenticated={authenticated} />
                ) : (
                  <>
                    {currentView == 'order' ? (
                      <>
                        {blocked ? (
                          <Block config={props.config} />
                        ) : (
                          <>
                            {config.fms ? (
                              <iframe src="https://www.airport-zurich.taxi/de/order/"></iframe>
                            ) : (
                              <Order config={config} setOrder={setOrder} />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <OrderList config={config} setOrder={setOrder} />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Widget;
