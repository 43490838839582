import { Button, Phone } from '@yourmileag/ui-kit';
import React from 'react';
import { Frame } from "./Frame";
import { IconBlock } from './IconBlock';

export function Block(props) {
    return (
        <div className={'slide'}>
            <Frame>
                <div className={'content'}>
                    <div className={'card'}>
                        <div className={'card__icon'}>
                            <IconBlock />
                        </div>
                        <h1>New rides on hold</h1>
                        <p className={'narrow'}>During the night from 22:30 until 06:00 we can't accept new rides.</p>
                        <p className={'narrow'}>Please call us in urgent situations.</p>
                        <Button className={'custom_button'} onPress={() => window.location = 'tel:' + props.config.phone} size={'l'}
                            iconLeft={<Phone />} variant={'special'}>{props.config.phone}</Button>
                    </div>
                </div>
            </Frame>
        </div>
    );
}
