import React from 'react';

export function Footer(props) {
    return (
        <div className={'footer'}>
            <p className={'legal'}>
                {props.config.partnerName} - <a href="/" onClick={() => { localStorage.removeItem('basicauth') }}>Log out</a>
            </p>
        </div>
    );
}