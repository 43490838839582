import React from 'react';

import { TextInput, Crossmark } from '@yourmileag/ui-kit';


export function ExtendableField(props) {
  const [result, setResult] = React.useState({ features: [] });
  const [focusIndex, setFocusIndex] = React.useState(0);

  function loadResults(value) {
    var valueParts = value.split(/,|-| /);
    var url = props.config.api.url + '/essentials/pois?fields=name,source,position';
    console.log(valueParts);
    var cleanValueParts = [];
    valueParts.forEach(function (value) {
      if (value) {
        cleanValueParts.push(value);
      }
    });
    if (cleanValueParts.length > 0) {
      url = url + '&qo=name:' + cleanValueParts.join(',') + ';' + 'source:' + cleanValueParts.join(',');
    }

    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('get', url);
    request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
    request.onload = function () {
      let response = request.response;
      let features = [];
      response.items.forEach(function (item) {
        var center = JSON.parse(item.position);
        features.push(
          {
            place_name: item.name + ', ' + item.source,
            center: [
              center.longitude,
              center.latitude
            ],
          }
        )
      });
      setResult({
        features: features,
      });
    };
    request.send();
  }

  function onChange(event) {
    var value = event.target.value;
    props.onChange(value);

    loadResults(value);

    return;

    var url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + value + '.json?country=ch&language=de&access_token=pk.eyJ1IjoieW91cm1pbGUiLCJhIjoiY2p5aTZ5NXpvMDZsMTNubzdrNjFyM2dweSJ9.U5UbUUffC4e1WTKk-42yaQ'

    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('get', url);
    request.onload = function () {
      let response = request.response;
      let features = response.features;
      response.features = features;
      setResult(response);
    };
    request.send();
  }

  function onKeyDown(event) {
    var keyCode = event.keyCode || event.which;
    if (keyCode == 38) {
      event.preventDefault();
      if (focusIndex == 0) {
        setFocusIndex(result.features.length - 1);
      } else {
        setFocusIndex(focusIndex - 1);
      }
    } else if (keyCode == 40) {
      event.preventDefault();
      if (focusIndex == result.features.length - 1) {
        setFocusIndex(0);
      } else {
        setFocusIndex(focusIndex + 1);
      }
    } else if (keyCode == 13) {
      event.preventDefault();
      if (result.features.length > 0) {
        onSelect(result.features[focusIndex]);
        const target = event.target
        window.setTimeout(function () {
          target.blur();
        }, 50);
      }
    } else if (keyCode == 27) {
      event.preventDefault();
      event.target.blur();
    } else {
      setFocusIndex(0);
    }
  }

  function onFocus(event) {
    props.onExtend(event);
    loadResults('');
  }

  function onBlur(event) {
    props.onCollapse(event);
  }

  function onSelect(feature) {
    window.setTimeout(function () {
      props.onSelect(feature);
    }, 100);
  }

  return (
    <div className={'form_row'}>
      <div className={'extendable_field' + (props.extended ? ' extendable_field--extended' : '')}
        style={props.inputRect ? {
          'position': 'absolute',
          'top': JSON.parse(props.inputRect).top,
          'left': 30,
          'right': 30,
          'zIndex': 1,
        } : {}}>
        <div className={'extendable_field__input'}>
          <TextInput required value={props.value} label={props.label} clean onFocus={onFocus}
            actionIcon={props.extended ? <Crossmark /> : null} onActionIconClick={onBlur}
            onBlur={onBlur} onChange={onChange} onKeyDown={onKeyDown} tabIndex={props.tabIndex} />
        </div>
        {result.features && (
          <ul className={'extendable_field__list'}>
            {result.features.map((feature, index) => {
              return (
                <li>
                  <a href={'#'} onClick={() => onSelect(feature)} tabIndex={'-1'} class={focusIndex == index ? 'focus' : ''}>
                    <span dangerouslySetInnerHTML={{ __html: '<b>' + feature.place_name.replace(',', '</b>,') }} />
                  </a>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      <span className={'extendable_field__dummy'}
        style={{ 'display': props.inputRect ? 'block' : 'none' }}></span>
    </div>
  );
}
